'use client'

import { Button, Image } from '@vinted/web-ui'

import { Pencil24 } from '@vinted/monochrome-icons'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useSession } from '@marketplace-web/shared/session'

import { navigateToPage } from '_libs/utils/window'
import { clickEvent } from '_libs/common/event-tracker/events'
import { EDIT_ITEM_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

import { SELLER_INSIGHTS_TAB_NAME } from '../constants'

type Props = {
  accessibilityDescription: string
  photoUrl: string
  itemId: number
}

const SellerInsightsPhotoThumbnail = ({ accessibilityDescription, photoUrl, itemId }: Props) => {
  const { track } = useTracking()
  const { user: currentUser } = useSession()

  const handleButtonClick = () => {
    track(
      clickEvent({
        screen: Screen.CurrentUserProfile,
        target: ClickableElement.EditItem,
        targetDetails: JSON.stringify({
          item_id: itemId,
          user_id: currentUser?.id,
        }),
      }),
    )

    navigateToPage(EDIT_ITEM_URL(itemId, SELLER_INSIGHTS_TAB_NAME))
  }

  return (
    <div className="u-position-relative u-inline-block seller-insights-photo-recommendations__item">
      <button type="button" onClick={handleButtonClick}>
        <Image
          src={photoUrl}
          scaling={Image.Scaling.Cover}
          styling={Image.Styling.Rounded}
          ratio={Image.Ratio.Square}
          size={Image.Size.X4Large}
          alt={accessibilityDescription}
          testId={`item-to-improve-${itemId}`}
        />
      </button>
      <div className="u-position-absolute u-right u-bottom u-zindex-bump u-margin-medium">
        <Button
          inline
          inverse
          iconName={Pencil24}
          size={Button.Size.Medium}
          styling={Button.Styling.Filled}
          theme="amplified"
          onClick={handleButtonClick}
          aria={{ 'aria-label': accessibilityDescription }}
        />
      </div>
    </div>
  )
}

export default SellerInsightsPhotoThumbnail
