'use client'

import { navigateToPage } from '_libs/utils/window'

import { useTracking } from '@marketplace-web/shared/event-tracker'

import { clickEvent } from '_libs/common/event-tracker/events'
import { getBumpableItemDataFromModel } from 'data/utils/bump'

import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { BUMP_MULTIPLE_ITEM_SELECTION_URL } from 'constants/routes'

import { BumpableItemPreCheckoutModel, ProductItemModel, VasEntryPointModel } from 'types/models'

import VasBanner from '../VasBanner/VasBanner'
import useStartBumpCheckout from '../../hooks/useStartBumpCheckout'
import { VasImages } from '../../constants/vas-entry-point'

type Props = {
  bumpBanner?: VasEntryPointModel
  getSingleBumpableItem: () => ProductItemModel | undefined
}

const BumpBanner = ({ bumpBanner, getSingleBumpableItem }: Props) => {
  const { track } = useTracking()
  const { startCheckout } = useStartBumpCheckout()

  if (!bumpBanner) return null

  const handleBumpBannerClick = () => {
    track(
      clickEvent({
        screen: Screen.CurrentUserProfile,
        target: ClickableElement.MultiplePushUp,
      }),
    )

    if (bumpBanner.selectedItemId) {
      const bumpableItem = getSingleBumpableItem()

      if (bumpableItem) {
        startCheckout(getBumpableItemDataFromModel(bumpableItem))

        return
      }

      const selectedItem: BumpableItemPreCheckoutModel = {
        id: bumpBanner.selectedItemId,
        title: null,
        thumbnail: null,
      }

      startCheckout(selectedItem)

      return
    }

    navigateToPage(BUMP_MULTIPLE_ITEM_SELECTION_URL)
  }

  const bannerProps = {
    ...bumpBanner,
    onClick: handleBumpBannerClick,
    testId: 'bump-banner',
    icon: VasImages.VasBump,
  }

  return <VasBanner {...bannerProps} />
}

export default BumpBanner
