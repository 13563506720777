'use client'

import { ChangeEvent, useState } from 'react'
import {
  Button,
  Cell,
  Divider,
  Image,
  InputTextArea,
  Rating,
  Spacer,
  Text,
  Validation,
} from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'
import useAsset from 'hooks/useAsset'
import UserImage from 'components/UserImage'

import { EMPTY_USER_IMAGE_NAME } from 'constants/images'
import { getItemThumbnail } from 'data/utils/item'
import { getUserThumbnail } from 'data/utils/user'

import { TransactionDto, UserDto } from 'types/dtos'

const CHARACTER_LIMIT = 400

export type FeedbackProps = {
  rating?: number
  feedback?: string
}

type Props = FeedbackProps & {
  isEditDisabled?: boolean
  returnUrl: string
  transaction?: TransactionDto
  user: UserDto
  isLoading: boolean
  onSave: (feedback: string, rating: number) => void
  formErrors?: Record<string, string>
}

const FeedbackForm = ({
  rating = 0,
  feedback = '',
  isEditDisabled,
  returnUrl,
  transaction,
  user,
  isLoading,
  onSave,
  formErrors,
}: Props) => {
  const translate = useTranslate('user.feedback.feedback_form')
  const asset = useAsset('assets/no-photo')
  const [finalRating, setFinalRating] = useState(rating)
  const [finalFeedback, setFinalFeedback] = useState(feedback)

  function handleSubmit(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) {
    if (isLoading) {
      event.preventDefault()

      return
    }

    onSave(finalFeedback, finalRating)
  }

  function handleInputChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setFinalFeedback(event.target.value)
  }

  function handleRatingChange(selectedRating: number) {
    setFinalRating(selectedRating)
  }

  function renderUser() {
    return (
      <Cell
        prefix={
          <UserImage
            src={getUserThumbnail(user.photo) || asset(EMPTY_USER_IMAGE_NAME)}
            size={Image.Size.Regular}
            styling={Image.Styling.Circle}
          />
        }
        body={<Text as="span">{user.login}</Text>}
      />
    )
  }

  function renderItem() {
    if (!transaction) return null

    const {
      item_title,
      order: { items },
    } = transaction

    const photos = items?.[0]?.photos

    return (
      <>
        <Divider />
        <Cell
          prefix={
            photos && (
              <Image
                alt={item_title}
                src={getItemThumbnail(photos)}
                styling={Image.Styling.Rounded}
                size={Image.Size.Regular}
                scaling={Image.Scaling.Cover}
              />
            )
          }
          body={<Text as="span">{item_title}</Text>}
        />
      </>
    )
  }

  function renderActions() {
    if (isEditDisabled) return null

    return (
      <div className="u-flexbox u-justify-content-flex-end u-ui-padding-medium">
        <Button
          size={Button.Size.Medium}
          inverse
          styling={Button.Styling.Filled}
          text={translate('actions.cancel')}
          inline
          url={returnUrl}
        />
        <Spacer orientation={Spacer.Orientation.Vertical} />
        <Button
          size={Button.Size.Medium}
          styling={Button.Styling.Filled}
          text={translate('actions.submit')}
          isLoading={isLoading}
          onClick={handleSubmit}
          inline
          testId="feedback-form-submit-button"
        />
      </div>
    )
  }

  function renderFieldError(field: string) {
    if (!formErrors?.[field]) return null

    return <Validation text={formErrors[field]} theme="warning" />
  }

  function renderRating() {
    const text = !!finalRating && translate(`rating.rate_${finalRating}`)
    const fieldError = renderFieldError('rating')

    return (
      <div className="u-ui-padding-large">
        <div className="u-flexbox u-justify-content-between">
          <Rating
            isInteractive={!isEditDisabled}
            value={finalRating}
            onSelect={handleRatingChange}
            size={Rating.Size.Large}
            aria={{
              'aria-labelledby': undefined,
            }}
          />
          <Text as="span" text={text} />
        </div>
        {fieldError && <div className="u-ui-padding-top-medium">{fieldError}</div>}
      </div>
    )
  }

  function renderFeedbackForm() {
    const fieldName = 'feedback'
    const validation =
      renderFieldError(fieldName) ||
      translate('character_limit', {
        limit: `${finalFeedback.length}/${CHARACTER_LIMIT}`,
      })

    return (
      <Cell styling={Cell.Styling.Tight}>
        {renderRating()}
        <Divider />
        <InputTextArea
          name={fieldName}
          maxLength={CHARACTER_LIMIT}
          placeholder={translate('placeholder')}
          value={finalFeedback}
          onChange={handleInputChange}
          validation={isEditDisabled ? null : validation}
          disabled={isEditDisabled}
        />
        {renderActions()}
      </Cell>
    )
  }

  return (
    <>
      {renderUser()}
      {renderItem()}
      <Spacer size={Spacer.Size.Large} />
      {renderFeedbackForm()}
    </>
  )
}

export default FeedbackForm
