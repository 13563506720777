'use client'

import { InView } from 'react-intersection-observer'
import { Button, Spacer } from '@vinted/web-ui'

import { ProfileItemBumpButton, PerformanceChartButton } from '@marketplace-web/domain/vas'
import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import ItemGrid from 'components/ItemGrid'
import { ProductItem } from 'components/ProductItem'
import { addClickListItemTracking } from 'containers/TrackingProvider/hocs'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useAbTest, useTrackAbTestCallback } from '@marketplace-web/shared/ab-tests'
import useTranslate from 'hooks/useTranslate'

import { ItemAlertStatus } from 'constants/item'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { ContentSource } from 'constants/tracking/content-sources'
import { ListItemContentType } from 'constants/tracking/content-types'
import { EDIT_ITEM_URL, ITEM_URL } from 'constants/routes'
import { Screen } from 'constants/tracking/screens'

import { getProductItemStatus } from 'data/utils/item'
import { clickEvent, impressionEvent } from '_libs/common/event-tracker/events'
import { GridItem, ItemVisibleCallbackArgs } from 'types/components'
import { logClosetItemError } from 'pages/Profile/utils/log'
import { ProductItemModel } from 'types/models'

type Props = {
  items: Array<ProductItemModel>
  userId: number
  allowBump: boolean
  isViewingSelf: boolean
}

const TrackedProductItem = addClickListItemTracking(ProductItem)

const ItemsContainer = ({ items, userId, allowBump, isViewingSelf }: Props) => {
  const { track } = useTracking()
  const translate = useTranslate('item')

  const isEditButtonEnabled = useFeatureSwitch('web_wardrobe_edit_button')
  const editButtonABTest = useAbTest('edit_button_test_v2')
  const trackAbTest = useTrackAbTestCallback()

  const showEditButton = isEditButtonEnabled && editButtonABTest?.variant === 'on'

  const handleItemVisibility = ({
    item: { data, id },
    index,
  }: ItemVisibleCallbackArgs<ProductItemModel>) => {
    const event = impressionEvent({
      id,
      position: index + 1,
      contentType: ListItemContentType.Item,
      contentSource: ContentSource.UserItems,
      itemOwnerId: data.user.id || userId,
    })

    track(event)
  }

  const handleErrorLogging = (err: Error | null) => logClosetItemError(err)

  const onEditItemClick = () =>
    track(
      clickEvent({
        target: ClickableElement.EditItem,
        screen: Screen.CurrentUserProfile,
      }),
    )

  const renderABTestEditButton = (item: ProductItemModel) => (
    <Button
      text={translate('wardrobe.actions.edit')}
      theme="primary"
      size={Button.Size.Small}
      url={EDIT_ITEM_URL(item.id)}
      onClick={onEditItemClick}
      disabled={!item.canEdit}
    />
  )

  const renderItemFooter = (item: ProductItemModel) => {
    const { id } = item

    if (!isViewingSelf) return null

    const status = getProductItemStatus(item, isViewingSelf)

    switch (status) {
      case ItemAlertStatus.ReplicaProof:
      case ItemAlertStatus.UnderReview:
        return (
          <Button
            text={translate('wardrobe.actions.edit')}
            theme="primary"
            size={Button.Size.Small}
            url={EDIT_ITEM_URL(id)}
          />
        )

      case 'bumped': {
        return (
          <>
            <PerformanceChartButton itemId={id} />
            {showEditButton && (
              <>
                <Spacer size={Spacer.Size.Small} />
                {renderABTestEditButton(item)}
              </>
            )}
          </>
        )
      }

      case 'draft': {
        return (
          <Button
            text={translate('drafts.finish_editing')}
            theme="primary"
            size={Button.Size.Small}
            url={EDIT_ITEM_URL(id)}
          />
        )
      }

      default: {
        if (allowBump)
          return (
            <>
              <ProfileItemBumpButton item={item} />
              {showEditButton && (
                <>
                  <Spacer size={Spacer.Size.Small} />
                  {renderABTestEditButton(item)}
                </>
              )}
            </>
          )

        return showEditButton ? renderABTestEditButton(item) : null
      }
    }
  }

  const renderItem = ({ data }: GridItem<ProductItemModel>, index: number) => (
    <InView
      onChange={inView => {
        if (!isViewingSelf) return
        if (index > 0) return
        if (!inView) return
        if (!isEditButtonEnabled || !editButtonABTest) return

        trackAbTest(editButtonABTest)
      }}
    >
      <TrackedProductItem
        showOwner={false}
        item={data}
        renderFooter={renderItemFooter(data)}
        showStatus
        viewingSelf={isViewingSelf}
        tracking={{
          id: data.id,
          contentType: ListItemContentType.Item,
          contentSource: ContentSource.UserItems,
          position: index + 1,
          screen: isViewingSelf ? Screen.CurrentUserProfile : Screen.UserProfile,
        }}
        url={data.isDraft ? EDIT_ITEM_URL(data.id) : ITEM_URL(data.id)}
        hideBusinessBadge
        isBumpTextHidden
      />
    </InView>
  )

  return (
    <ItemGrid
      items={items.map(item => ({ id: item.id, data: item }))}
      renderItem={renderItem}
      onItemVisible={handleItemVisibility}
      widthResolver={() => 'one-fifth'}
      preventLog
      handleErrorLogging={handleErrorLogging}
    />
  )
}

export default ItemsContainer
