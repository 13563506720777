'use client'

import { Cell, Text, Rating, Spacer, Image, Icon, Button, Divider } from '@vinted/web-ui'
import { HorizontalDots24, Copy24, Flag24 } from '@vinted/monochrome-icons'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { FormattedDateMessage } from 'components/@support'
import DropdownMenu from 'components/DropdownMenu'

import { Translation } from 'constants/translation'
import { EDIT_FEEDBACK_URL, FEEDBACK_URL, NOTICE_AND_ACTION_FORM } from 'constants/routes'
import { UiState } from 'constants/ui'

import useTranslate from 'hooks/useTranslate'
import useLocation from 'hooks/useLocation'

import FeedbackUsername from './FeedbackUsername'
import FeedbackThumbnail from './FeedbackThumbnail'
import FeedbackActions from './FeedbackActions'

import CommentInput from './CommentInput'
import Comment from './Comment'
import DeleteFeedbackModal from './DeleteFeedbackModal'
import FeedbackTranslationButton from './FeedbackTranslationButton'
import { useFeedbackContext, FeedbackScreen } from '../FeedbackProvider'
import ModerateFeedbackModal from './ModerateFeedbackModal'

type Props = {
  id: number
  userId: number
  isContentTranslationEnabled: boolean
}

const FeedbackItem = ({ id, userId, isContentTranslationEnabled }: Props) => {
  const translate = useTranslate('user')
  const { baseUrl } = useLocation()

  const {
    getFeedbackById,
    getFeedbackItemStateById,
    setScreenById,
    deleteFeedbackById,
    deleteCommentByFeedbackId,
    moderateFeedbackById,
  } = useFeedbackContext()
  const feedback = getFeedbackById(id)
  const screen = getFeedbackItemStateById(id)?.screen

  const isFeedbackReportingEnabled = useFeatureSwitch('feedback_reporting') && !feedback?.isSystem

  const handleCopyClick = () => {
    navigator.clipboard.writeText(`${baseUrl}${FEEDBACK_URL(id)}`)
  }

  const renderUsername = () => {
    if (!feedback) return null

    return <FeedbackUsername user={feedback.user} isSystem={feedback.isSystem} />
  }

  const renderThumbnail = () => {
    if (!feedback) return null

    return (
      <FeedbackThumbnail
        user={feedback.user}
        size={Image.Size.Large}
        isSystem={feedback.isSystem}
      />
    )
  }

  const renderDate = () => {
    if (!feedback) return null

    return <FormattedDateMessage date={new Date(feedback.createdAt)} relativeDate />
  }

  const renderRating = () => {
    if (!feedback) return null

    return (
      <Rating
        value={feedback.rating}
        aria={{
          'aria-label': feedback.rating
            ? translate('a11y.rating', { rating: feedback.rating, max_rating: 5 })
            : '',
        }}
      />
    )
  }

  const renderFeedback = () => {
    if (!feedback) return null

    return <Text as="span">{feedback.text}</Text>
  }

  const renderFeedbackWithDate = () => (
    <>
      {renderFeedback()}
      <Spacer size={Spacer.Size.Small} />
      <Text as="h3" type={Text.Type.Subtitle} theme="muted" text={renderDate()} />
    </>
  )

  const renderActions = () => {
    if (!feedback) return null

    return (
      <FeedbackActions
        id={id}
        editFeedbackUrl={EDIT_FEEDBACK_URL(userId, id)}
        canReply={feedback.canReply}
        canChange={feedback.canChange}
        canDelete={feedback.canDelete}
        canModerate={feedback.canModerate}
      />
    )
  }

  const renderComment = () => {
    switch (screen) {
      case FeedbackScreen.CreateComment:
      case FeedbackScreen.EditComment:
        return <CommentInput id={id} />
      default:
        return (
          feedback?.comment && (
            <Comment
              id={id}
              comment={feedback.comment}
              canChangeComment={feedback.canChangeComment}
              canDeleteComment={feedback.canDeleteComment}
            />
          )
        )
    }
  }

  const handleFeedbackDelete = () => {
    switch (screen) {
      case FeedbackScreen.DeleteFeedback:
        deleteFeedbackById(id)
        break
      case FeedbackScreen.DeleteComment:
        deleteCommentByFeedbackId(id)
        break
      default:
        break
    }
  }

  const handleFeedbackModerate = () => {
    const uiState = getFeedbackItemStateById(id)?.uiState

    if (!feedback || uiState === UiState.Pending) return

    switch (screen) {
      case FeedbackScreen.ModerateFeedback:
        moderateFeedbackById(id, feedback.feedbackUserId, feedback.userId)
        break

      default:
        break
    }
  }

  const handleCancel = () => {
    setScreenById(id, FeedbackScreen.View)
  }

  const renderDeleteFeedbackModal = () => (
    <DeleteFeedbackModal
      isOpen={screen === FeedbackScreen.DeleteFeedback || screen === FeedbackScreen.DeleteComment}
      onSubmit={handleFeedbackDelete}
      onClose={handleCancel}
    />
  )

  const renderModerateFeedbackModal = () => (
    <ModerateFeedbackModal
      isOpen={screen === FeedbackScreen.ModerateFeedback}
      onSubmit={handleFeedbackModerate}
      onClose={handleCancel}
    />
  )

  const renderTranslationButton = () => {
    if (
      !isContentTranslationEnabled ||
      !feedback?.localization ||
      feedback.localization === Translation.None
    )
      return null

    return (
      <>
        <Spacer />
        <FeedbackTranslationButton feedbackId={id} />
      </>
    )
  }

  const renderDropdownMenu = () => {
    const buttonProps = {
      icon: <Icon name={HorizontalDots24} />,
      styling: Button.Styling.Flat,
    }

    const dropdownActions = [
      {
        id: 'copy',
        text: <Text as="span" text={translate('feedback.copy')} theme="amplified" />,
        prefix: <Icon name={Copy24} />,
        onClick: handleCopyClick,
      },
      {
        id: 'report',
        text: <Text as="span" text={translate('feedback.report')} theme="amplified" />,
        prefix: <Icon name={Flag24} color={Icon.Color.Warning} />,
        url: NOTICE_AND_ACTION_FORM,
      },
    ]

    return (
      <DropdownMenu
        styling={Cell.Styling.Default}
        separator={<Divider />}
        actions={dropdownActions}
        buttonProps={buttonProps}
      />
    )
  }

  return (
    <Cell
      title={renderUsername()}
      prefix={renderThumbnail()}
      subtitle={isFeedbackReportingEnabled ? renderDropdownMenu() : renderDate()}
      styling={Cell.Styling.Tight}
      testId="feedback-item"
    >
      {renderRating()}
      <Spacer />
      {isFeedbackReportingEnabled ? renderFeedbackWithDate() : renderFeedback()}
      <Spacer />
      {renderActions()}
      <Spacer />
      {renderComment()}
      {renderTranslationButton()}
      {renderDeleteFeedbackModal()}
      {renderModerateFeedbackModal()}
    </Cell>
  )
}

export default FeedbackItem
