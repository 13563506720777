'use client'

import ContentLoader from 'components/ContentLoader'
import { useAbTest, useTrackAbTest } from '@marketplace-web/shared/ab-tests'
import { SellerInsightsType } from 'constants/seller-insights'

import useGetPhotoRecommendations from './hooks/useGetPhotoRecommendations'
import SellerInsightsPhotoRecommendations from './SellerInsightsPhotoRecommendations/SellerInsightsPhotoRecommendations'
import SellerInsightsBlocks from './SellerInsightsBlocks'
import SellerInsightsTipsBanner from './SellerInsightsTipsBanner'
import SustainabilityImpact from './SustainabilityImpact'

type Props = {
  sellerInsights: SellerInsightsType
  isSellerInsightsLoading: boolean
}

const SellerInsights = ({ sellerInsights, isSellerInsightsLoading }: Props) => {
  const { photoRecommendations, isPhotoRecommendationsLoading } = useGetPhotoRecommendations()

  const sellerInsightsTipsBannerAbTest = useAbTest('seller_insights_tip_banner')
  const isSellerInsightsTipsBannerEnabled =
    sellerInsightsTipsBannerAbTest && sellerInsightsTipsBannerAbTest.variant !== 'off'
  useTrackAbTest(sellerInsightsTipsBannerAbTest)

  const sustainabilityImpactAbTest = useAbTest('seller_insights_sustainability')
  const isSustainabilityImpactAbTestEnabled =
    sustainabilityImpactAbTest && sustainabilityImpactAbTest.variant !== 'off'
  useTrackAbTest(sustainabilityImpactAbTest)

  if (isSellerInsightsLoading || isPhotoRecommendationsLoading)
    return <ContentLoader testId="seller-insights-loader" />

  if (!sellerInsights?.isVisible) return null

  return (
    <section>
      {isSustainabilityImpactAbTestEnabled && <SustainabilityImpact />}
      <div className="seller-blocks-container">
        {isSellerInsightsTipsBannerEnabled && <SellerInsightsTipsBanner />}
        {sellerInsights?.isVisible && <SellerInsightsBlocks sellerInsights={sellerInsights} />}
      </div>
      {photoRecommendations && <SellerInsightsPhotoRecommendations {...photoRecommendations} />}
    </section>
  )
}

export default SellerInsights
