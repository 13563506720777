'use client'

import { Icon } from '@vinted/web-ui'

import {
  AngryFace48,
  HappyFaceFilled48,
  LaughingFace48,
  NeutralFace48,
  SadFaceFilled48,
} from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'

type Props = {
  rating: number
  isActive: boolean
  onClick: (rating: number) => void
}

const getIconName = (rating: number) => {
  switch (rating) {
    case 1:
      return AngryFace48
    case 2:
      return SadFaceFilled48
    case 3:
      return NeutralFace48
    case 4:
      return HappyFaceFilled48
    case 5:
      return LaughingFace48
    default:
      return NeutralFace48
  }
}

const FaceIcon = ({ rating, isActive, onClick }: Props) => {
  const translate = useTranslate('user.feedback.feedback_form.rating')

  function handleFaceSelect() {
    onClick(rating)
  }

  const iconColor = isActive ? Icon.Color.Primary : Icon.Color.GreyscaleLevel2

  return (
    <button
      type="button"
      className="u-cursor-pointer"
      data-testid={`feedback-resolved-face-icon-${rating}`}
      onClick={handleFaceSelect}
      title={translate(`rate_${rating}`)}
      aria-pressed={isActive}
    >
      <Icon name={getIconName(rating)} color={iconColor} />
    </button>
  )
}

export { FaceIcon }
