'use client'

import { useEffect, useState } from 'react'
import { Spacer, Text, Loader } from '@vinted/web-ui'

import useFetch from 'hooks/useFetch'
import useLocation from 'hooks/useLocation'
import useRefUrl from 'hooks/useRefUrl'
import useTranslate from 'hooks/useTranslate'

import { UiState } from 'constants/ui'
import { ITEM_URL, MEMBER_PROFILE_URL, ROOT_URL } from 'constants/routes'

import { Response, FeedbackResp, ResponseError } from 'types/api'
import {
  getTransaction,
  getUserById,
  getFeedback,
  editUserFeedback,
  createUserFeedback,
} from 'data/api'
import { ResponseCode } from 'data/api/response-codes'
import { redirectToPage } from '_libs/utils/window'
import { urlWithParams } from '_libs/utils/url'

import FeedbackForm, { FeedbackProps } from './FeedbackForm'

type Props = {
  isEditDisabled?: boolean
  feedbackId?: number
  userId?: number
}

const LeaveProfileFeedback = ({ feedbackId, userId, isEditDisabled }: Props) => {
  const [formState, setFormState] = useState(UiState.Idle)
  const [formErrors, setFormErrors] = useState<Record<string, string>>()
  const refUrl = useRefUrl('')

  const { transformedData: transaction, fetch: fetchTransaction } = useFetch(
    getTransaction,
    response => response.transaction,
  )

  const { transformedData: user, fetch: fetchUser } = useFetch(
    getUserById,
    response => response.user,
  )

  const { transformedData: currentFeedback, fetch: fetchFeedback } = useFetch(
    getFeedback,
    response => response.user_feedback,
  )

  const translate = useTranslate('user.feedback.feedback_form')

  const { transaction_id, referer } = useLocation().searchParams
  const transactionId = Number.isNaN(transaction_id) ? undefined : Number(transaction_id)
  const isLoaded = transactionId ? !!transaction : !!user && !!currentFeedback
  const transactionUser =
    transaction?.user_side === 'buyer' ? transaction?.seller : transaction?.buyer
  const feedbackUser = transactionUser || user
  const showOfflineVerificationFeedback =
    transaction?.offline_verification &&
    transaction?.user_side === 'buyer' &&
    referer === 'inbox' &&
    transactionId &&
    !!refUrl

  useEffect(() => {
    if (!transactionId) return

    fetchTransaction({ id: transactionId })
  }, [fetchTransaction, transactionId])

  useEffect(() => {
    if (transactionId) return
    if (!feedbackId) return

    fetchFeedback({ feedbackId })
  }, [feedbackId, fetchFeedback, transactionId])

  useEffect(() => {
    const feedbackUserId = userId || currentFeedback?.user_id

    if (transactionId) return
    if (!feedbackUserId) return

    fetchUser({ id: feedbackUserId })
  }, [fetchUser, transactionId, userId, currentFeedback])

  function validateData(feedback: string, rating: number) {
    const newFormErrors: Record<string, string> = {}

    if (!rating) {
      newFormErrors.rating = translate('validation.rating_empty')
    }

    if (!feedback) {
      newFormErrors.rating = translate('validation.feedback_empty')
    }

    if (!Object.keys(newFormErrors).length) return true

    setFormState(UiState.Failure)
    setFormErrors(newFormErrors)

    return false
  }

  function getReturnUrl() {
    if (!transactionId && userId) {
      return `${MEMBER_PROFILE_URL(userId)}?tab=feedback`
    }

    if (refUrl) {
      return refUrl
    }

    if (transaction?.item_id) {
      return ITEM_URL(transaction?.item_id)
    }

    if (userId) {
      return `${MEMBER_PROFILE_URL(userId)}?tab=feedback`
    }

    return ROOT_URL
  }

  function getSuccessReturnUrl() {
    if (showOfflineVerificationFeedback) {
      return urlWithParams(refUrl, {
        offline_verification_feedback_seller: true,
      })
    }

    return getReturnUrl()
  }

  async function handleSave(feedback: string, rating: number) {
    if (!validateData(feedback, rating)) return

    setFormState(UiState.Pending)

    let response: Response<FeedbackResp> | ResponseError

    if (currentFeedback) {
      response = await editUserFeedback({
        feedbackId: currentFeedback.id,
        feedback,
        rating,
      })
    } else {
      if (!userId) return

      response = await createUserFeedback({
        userId,
        feedback,
        rating,
        transactionId,
      })
    }

    const returnUrl = getSuccessReturnUrl()

    if (response.code === ResponseCode.Ok) {
      redirectToPage(returnUrl)

      return
    }

    if ('errors' in response) {
      setFormState(UiState.Failure)

      if (response.code === ResponseCode.ValidationError) {
        const newFormErrors: Record<string, string> = response.errors.reduce(
          (acc, { field, value }) => ({ ...acc, [field]: value }),
          {},
        )

        setFormErrors(newFormErrors)

        return
      }

      setFormErrors({ feedback: response.message })
    }
  }

  function renderContent() {
    const returnUrl = getReturnUrl()

    if (!feedbackUser || !isLoaded) {
      return <Loader testId="leave-profile-feedback-loader" />
    }

    let formProps: FeedbackProps = {}
    if (currentFeedback) {
      const { rating, feedback } = currentFeedback

      formProps = {
        rating,
        feedback,
      }
    }

    return (
      <FeedbackForm
        isEditDisabled={isEditDisabled}
        returnUrl={returnUrl}
        user={feedbackUser}
        transaction={transaction}
        isLoading={formState === UiState.Pending}
        formErrors={formErrors}
        onSave={handleSave}
        {...formProps}
      />
    )
  }

  return (
    <div className="medium-container">
      <div className="u-ui-padding-vertical-medium">
        <Text as="h1" type={Text.Type.Heading} width={Text.Width.Parent}>
          {isEditDisabled ? translate('no_edit_title') : translate('title')}
        </Text>
      </div>
      <Spacer />
      {renderContent()}
    </div>
  )
}

export default LeaveProfileFeedback
