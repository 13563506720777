'use client'

import { Chip, Spacer, Text } from '@vinted/web-ui'

import { useEffect, useState } from 'react'

import HorizontalScrollArea from 'components/HorizontalScrollArea'
import { SeparatedList } from '@marketplace-web/shared/ui-helpers'
import useTranslate from 'hooks/useTranslate'

import { getSellerFilters } from 'data/api'
import { transformGetSellerFiltersResponse } from 'data/api/transformers/response'
import useFetch from 'hooks/useFetch'

import { SellerFiltersType } from './utils'

type Props = {
  selectedSellerFilter: SellerFiltersType | undefined
  onFilterClick: (filter: SellerFiltersType) => void
}

const SellerFilters = ({ selectedSellerFilter, onFilterClick }: Props) => {
  const translate = useTranslate()
  const { fetch: fetchSellerFilters } = useFetch(
    getSellerFilters,
    transformGetSellerFiltersResponse,
  )

  const [filters, setFilters] = useState<Array<string>>([])

  const onClick = (filter: SellerFiltersType) => () => {
    onFilterClick(filter)
  }

  useEffect(() => {
    const getFiltersToUse = async () => {
      const { transformedData } = await fetchSellerFilters()

      if (transformedData) {
        const nonZeroFilters = Object.entries(transformedData).filter(([, count]) => Boolean(count))
        const allowedFilters = nonZeroFilters.filter(([name]) => SellerFiltersType[name])
        const sellerFilters = allowedFilters.map(([name]) => name)

        return setFilters(sellerFilters)
      }

      return setFilters(Object.keys(SellerFiltersType))
    }

    getFiltersToUse()
  }, [fetchSellerFilters])

  const renderViewSelfFilterList = () => {
    return (
      <SeparatedList
        separator={<Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Medium} />}
      >
        {filters.map(filter => {
          const filterKey = filter.toLowerCase()

          return (
            <Chip
              activated={selectedSellerFilter === SellerFiltersType[filter]}
              testId={`closet-seller-filters-${SellerFiltersType[filter] as string}`}
              text={translate(`profile.item_filters.${filterKey}`)}
              textType={Text.Type.Subtitle}
              onClick={onClick(SellerFiltersType[filter])}
              key={`closet-seller-filters-${filterKey}`}
              radius={Chip.Radius.Round}
            />
          )
        })}
      </SeparatedList>
    )
  }

  return (
    <>
      <div className="u-phones-only">
        <Spacer />
      </div>
      <HorizontalScrollArea showControls={false} disableIosSmoothScroll>
        <div className="u-flexbox" data-testid="closet-seller-filters">
          {renderViewSelfFilterList()}
        </div>
      </HorizontalScrollArea>
    </>
  )
}

export default SellerFilters
