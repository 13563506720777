'use client'

import { ReactElement, memo } from 'react'
import { isEqual, noop } from 'lodash'

import { ErrorBoundary } from '@marketplace-web/shared/ui-helpers'
import { GridItem, ItemVisibleCallbackArgs } from 'types/components'

import GridItemComponent from './GridItem/GridItem'

type ItemGridProps<T> = {
  items: Array<GridItem<T>>
  renderItem: (item: GridItem<T>, index: number) => ReactElement | null
  widthResolver?: (item: GridItem<T>) => 'full-row' | 'one-fifth' | null
  onItemVisible?: (callbackArgs: ItemVisibleCallbackArgs<T>) => void
  handleErrorLogging?: (err: Error | null) => void
  preventLog?: boolean
}

const ItemGrid = <T,>({
  items,
  renderItem,
  widthResolver = () => null,
  onItemVisible = noop,
  handleErrorLogging,
  preventLog,
}: ItemGridProps<T>) => (
  <div className="feed-grid">
    {items.map((item, index) => (
      <GridItemComponent
        key={item.id}
        width={widthResolver(item)}
        onVisible={() => onItemVisible({ item, index })}
      >
        <ErrorBoundary
          FallbackComponent={ErrorBoundary.ComponentError}
          preventLog={preventLog}
          onError={handleErrorLogging}
        >
          {renderItem(item, index)}
        </ErrorBoundary>
      </GridItemComponent>
    ))}
  </div>
)

// type assertion required for generics to work
export default memo(ItemGrid, isEqual) as typeof ItemGrid
