'use client'

import { ReactNode } from 'react'
import { Card, Cell, Icon, Image, Text } from '@vinted/web-ui'
import { ChevronRight24 } from '@vinted/monochrome-icons'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import useAsset from 'hooks/useAsset'
import { useAbTest, useTrackAbTest } from '@marketplace-web/shared/ab-tests'
import useTranslate from 'hooks/useTranslate'
import { useSession } from '@marketplace-web/shared/session'

import { REFERRALS_URL } from 'constants/routes'

type Props = {
  bottomSpacer?: ReactNode
}

const ReferralsBanner = ({ bottomSpacer }: Props) => {
  const translate = useTranslate('profile.banners.referrals')
  const isReferralsEnabled = useFeatureSwitch('referrals')
  const asset = useAsset('/assets/referrals')
  const { user } = useSession()

  const isImpersonatingBusiness = user?.impersonating_business

  const referralsVisibilityTest = useAbTest('referrals_visibility')
  useTrackAbTest(referralsVisibilityTest)

  if (referralsVisibilityTest?.variant !== 'on' || !isReferralsEnabled || isImpersonatingBusiness) {
    return null
  }

  return (
    <>
      <Card>
        <div className="u-overflow-hidden">
          <Cell
            prefix={<Image src={asset('gift-box.png')} size={Image.Size.XLarge} />}
            title={translate('title')}
            body={<Text as="span" text={translate('body')} />}
            url={REFERRALS_URL}
            suffix={
              <Icon
                name={ChevronRight24}
                color={Icon.Color.GreyscaleLevel2}
                display={Icon.Display.Block}
              />
            }
          />
        </div>
      </Card>
      {bottomSpacer}
    </>
  )
}

export default ReferralsBanner
