'use client'

import { Button, Card, Cell, Icon, Spacer, Text } from '@vinted/web-ui'
import { Jacket24, LightBulb24, Sweater24 } from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'
import { ITEM_UPLOAD_URL } from 'constants/routes'
import { navigateToPage } from '_libs/utils/window'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { clickEvent } from '_libs/common/event-tracker/events'
import { useTracking } from '@marketplace-web/shared/event-tracker'

const SellerInsightsTipsBanner = () => {
  const translate = useTranslate('profile.seller_insights.selling_tips')
  const { track } = useTracking()

  const handleClick = () => {
    track(
      clickEvent({
        target: ClickableElement.UploadItem,
        screen: Screen.SellerInsights,
      }),
    )

    navigateToPage(ITEM_UPLOAD_URL)
  }

  return (
    <div className="u-flexbox u-flex-direction-column u-flex-grow u-flex-1">
      <Spacer size={Spacer.Size.XLarge} />
      <Card testId="seller-insights-tips-banner">
        <div className="seller-insights-atom seller-insights-tips u-padding-large">
          <Cell
            prefix={
              <Icon
                name={LightBulb24}
                color={Icon.Color.GreyscaleLevel3}
                aria={{ 'aria-hidden': true }}
              />
            }
            title={translate('title')}
            body={translate('subtitle')}
            styling={Cell.Styling.Tight}
          />
          <Cell styling={Cell.Styling.Tight}>
            <div className="u-flexbox u-flex-direction-row u-padding-medium">
              <section className="u-flexbox u-flex-direction-column u-align-items-center u-half-width">
                <div className="seller-insights-tips-icon">
                  <Icon name={Jacket24} color={Icon.Color.Primary} aria={{ 'aria-hidden': true }} />
                </div>
                <Spacer size={Spacer.Size.Small} />
                <Text
                  alignment={Text.Alignment.Center}
                  text={translate('categories.category_1')}
                  type={Text.Type.Subtitle}
                  as="h3"
                />
              </section>
              <section className="u-flexbox u-flex-direction-column u-align-items-center u-half-width">
                <div className="seller-insights-tips-icon">
                  <Icon
                    name={Sweater24}
                    color={Icon.Color.Primary}
                    aria={{ 'aria-hidden': true }}
                  />
                </div>
                <Spacer size={Spacer.Size.Small} />
                <Text
                  alignment={Text.Alignment.Center}
                  text={translate('categories.category_2')}
                  type={Text.Type.Subtitle}
                  as="h3"
                />
              </section>
            </div>
          </Cell>
          <Button
            text={translate('actions.list_item')}
            size={Button.Size.Small}
            styling={Button.Styling.Filled}
            onClick={handleClick}
          />
        </div>
      </Card>
    </div>
  )
}

export default SellerInsightsTipsBanner
