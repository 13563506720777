'use client'

import { useEffect, MouseEvent } from 'react'
import { X24 } from '@vinted/monochrome-icons'
import { Button, Cell, Divider, Dialog, Navigation, Text, Image, Note } from '@vinted/web-ui'

import { useIsDarkModeEnabledFromCookies } from '@marketplace-web/shared/dark-mode'
import ContentLoader from 'components/ContentLoader'
import ErrorState from 'components/ErrorState'
import ScrollableArea from 'components/ScrollableArea'
import { SeparatedList } from '@marketplace-web/shared/ui-helpers'
import { UiState } from 'constants/ui'
import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import useTranslate from 'hooks/useTranslate'
import { clickEvent, viewScreenEvent } from '_libs/common/event-tracker/events'

import { TaxpayerEducationModel, TaxpayerEducationSectionModel } from 'types/models'

type Props = {
  show: boolean
  uiState: UiState
  taxpayerEducation: TaxpayerEducationModel | null
  isSpecialVerification: boolean
  onClose: () => void
  onConfirm?: () => void
}

const TaxpayerEducationModal = ({
  show,
  uiState,
  taxpayerEducation,
  isSpecialVerification,
  onClose,
  onConfirm,
}: Props) => {
  const translate = useTranslate(
    isSpecialVerification ? 'taxpayer_special_verification.education_modal' : 'taxpayer_education',
  )
  const { track } = useTracking()
  const isDarkMode = useIsDarkModeEnabledFromCookies()

  useEffect(() => {
    const trackSpecialVerificationEducationView = () => {
      track(viewScreenEvent({ screen: Screen.TaxpayersSpecialVerificationEducation }))
    }

    if (!isSpecialVerification || !show) return

    trackSpecialVerificationEducationView()
  }, [isSpecialVerification, show, track])

  const handleSectionClick = (event: MouseEvent) => {
    if (!isSpecialVerification) return
    if (!(event.target instanceof HTMLAnchorElement)) return

    track(
      clickEvent({
        screen: Screen.TaxpayersSpecialVerificationEducation,
        target: ClickableElement.InfoAboutDac7,
      }),
    )
  }

  const replaceNewLineSymbols = (sectionText: string) => sectionText.replace(/\n/g, '<br>')

  const renderSection = (section: TaxpayerEducationSectionModel) => {
    const iconUrl = isDarkMode ? section.darkIconUrl : section.iconUrl

    return (
      <Cell
        key={section.title}
        title={section.title}
        body={<Text as="span" html text={replaceNewLineSymbols(section.body)} />}
        onClick={handleSectionClick}
        prefix={
          <Image
            src={iconUrl}
            size={Image.Size.Medium}
            alt=""
            testId="taxpayer-education-section-icon"
          />
        }
        styling={Cell.Styling.Default}
      />
    )
  }

  const renderSections = () => {
    if (!taxpayerEducation) return null

    return taxpayerEducation.sections.map(renderSection)
  }

  const renderDisclaimer = () => {
    if (!isSpecialVerification) return null

    return <Note text={translate('disclaimer')} />
  }

  const renderNavigation = () => (
    <div className="u-overflow-y-unset">
      <Navigation
        body={<Text as="h2" type={Text.Type.Title} text={translate('header.title')} bold />}
        right={<Button styling={Button.Styling.Flat} onClick={onClose} iconName={X24} inline />}
      />
    </div>
  )

  const renderBody = () => (
    <ScrollableArea className="u-flex-direction-row">
      <Cell styling={Cell.Styling.Wide}>
        <Text as="span" html text={translate('body.information')} />
      </Cell>
      {renderSections()}
      {renderDisclaimer()}
    </ScrollableArea>
  )

  const renderFooter = () => {
    if (isSpecialVerification) {
      return (
        <div className="u-flex-direction-row u-overflow-y-unset">
          <Cell>
            <Button
              type={Button.Type.Button}
              text={translate('actions.start')}
              styling={Button.Styling.Filled}
              testId="taxpayer-education-start-button"
              onClick={onConfirm}
            />
          </Cell>
        </div>
      )
    }

    return (
      <div className="u-flex-direction-row u-overflow-y-unset">
        <Cell>
          <Button
            type={Button.Type.Button}
            text={translate('actions.start')}
            styling={Button.Styling.Filled}
            testId="taxpayer-education-start-button"
            onClick={onClose}
          />
        </Cell>
      </div>
    )
  }

  const renderModalContent = () => {
    if (uiState === UiState.Pending) return <ContentLoader testId="taxpayer-education-loader" />
    if (uiState === UiState.Failure) return <ErrorState />

    return (
      <SeparatedList separator={<Divider />}>
        {renderNavigation()}
        {renderBody()}
        {renderFooter()}
      </SeparatedList>
    )
  }

  return (
    <Dialog show={show} hasScrollableContent className="u-flexbox u-flex-direction-column">
      {renderModalContent()}
    </Dialog>
  )
}

export default TaxpayerEducationModal
